@import 'tailwindcss/base';

html {
    font-size: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
        sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    color: #666e78;
}

button:disabled {
    opacity: 0.5;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

input:disabled {
    background-color: #e3e3e3;
}

.booking-select .custom-select {
    &__control {
        border-radius: 5px;
        border-color: #666e78 !important;
        min-height: 37px;
        box-shadow: none;
    }
    &__indicator-separator {
        display: none;
    }
    &__dropdown-indicator {
        background-color: #666e78;
        padding: 8px 4px;
        height: 100%;
        display: flex;
        align-items: center;

        & svg {
            fill: white;
        }
    }
}

@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
